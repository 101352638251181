.reklamaDetails {
  border: 1px solid rgb(228, 228, 228);
  border-radius: 10px;
  padding: 20px;
  background-color: #fff;
}

.reklamaDetails:has(attr(open)) {
  color: red;
  border: none;
}

summary.reklamaAdd::marker {
  content: "+";
  font-size: 22px;
}

.reklamaAdd {
  font-size: 18px;
  font-weight: 700;
  padding-bottom: 15px;
  cursor: pointer;
}

.reklamaAdd:has(.ant-upload.ant-upload-select) {
  background-color: red;
}
