* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: Carolina;
  src: url("./fonts/Carolina.ttf");
}

@font-face {
  font-family: Inter;
  src: url("./fonts/Inter-Medium.otf");
}

@font-face {
  font-family: Bebas;
  src: url("./fonts/BEBAS_NEUE_CYRILLIC.ttf");
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: #e4e4e4;
  border-radius: 100px;
}

::-webkit-scrollbar-thumb {
  background-color: #3a3e47;
  border-radius: 100px;
}

fieldset {
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 10px;
  border: 1px solid silver;
}

legend {
  border: 1px solid silver !important;
  padding: 2px 15px !important;
  border-radius: 25px;
  width: auto !important;
  margin-bottom: 0px !important;
  color: black;
  font-size: 14px;
}

.ant-input-number {
  width: 100%;
}

.dynamic-delete-button {
  position: relative;
  top: 4px;
  margin: 0 8px;
  color: #999;
  font-size: 24px;
  cursor: pointer;
  transition: all 0.3s;
}

.dynamic-delete-button:hover {
  color: #777;
}

.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}

.user__image-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 10px;
}

/*!**!*/
/*.sun-editor {*/
/*    height: 100% !important;*/
/*}*/

/*.se-wrapper {*/
/*    height: 64vh !important;*/
/*}*/

.ant-upload-ant-upload-select div div div span div div {
  width: 100% !important;
  background-color: red;
}

th,
td {
  vertical-align: top;
  word-wrap: break-all;
}
